import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../../containers/Hosting/Navbar';

import Footer from '../../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../../components/seo';
import TopRibbon from '../../../containers/Hosting/TopRibbon';

import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Section1Wrapper from './section1Wrapper';
import Section2Wrapper from './section2Wrapper';
import ContactMongo from '../../../containers/Hosting/ContactMongo/index';
import RaghuImage from 'common/src/assets/image/hosting/Team/Raghu.jpg';
import AugustinImage from 'common/src/assets/image/hosting/testimonial/testimonial.jpeg';
const Webinarpage = ({
  row,
  col,
  margintop,
  title,
  subtitle,
  sec2_title,
  whitetitle,
  sec2row,
  sec2col1,
  sec2col2,
  decoration,
}) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    const loc = window.location.pathname;
    if (loc.substr(loc.length - 1) === '/') {
      setUrl(loc.substring(0, loc.length - 1));
    } else {
      setUrl(loc);
    }
  }, []);
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        {/* <SEO
          title="Webinar"
          description="Rapid Acceleration Partners provides practical AI and Intelligent RPA solutions. Extract structured data from unstructured content. Book a demo here."
        /> */}
        <Helmet>
          <title>
            Eliminating Chargeback Losses How Intelligent Process Automation Can
            Arrest The Leak
          </title>
          <meta
            name="description"
            content="Eliminating Chargeback Losses
            How Intelligent Process Automation Can Arrest The Leak"
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper className="HomePage">
          <TopRibbon />
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Section1Wrapper>
            {/*------------------------ 
                  TOPIC TO DISPLAY ON TOP 
              --------------------------*/}
            <Box {...decoration}>
              <Container>
                <Box {...margintop}>
                  <Heading
                    {...title}
                    content="Eliminating Revenue Losses Due to Chargebacks"
                  />
                  <Heading
                    {...subtitle}
                    content="How Intelligent Process Automation Can Arrest The Leak"
                  />
                </Box>
              </Container>
            </Box>
            {/*------------------------ 
                  SPLIT SECTION FOR FORMS 
              --------------------------*/}

            <Container>
              <Box {...row}>
                <Box {...col}>
                  <Heading {...sec2_title} content="About The Webinar" />
                  <div className="contents">
                    <p>
                      The aftermath of the pandemic led to a rise in Chargeback
                      disputes in the hospitality industry. These chargebacks
                      can be triggered by a variety of issues, such as billing
                      disputes, dissatisfaction with services or amenities, or
                      even simple misunderstandings.
                    </p>
                    <p>
                      The high frequency of chargebacks, coupled with the
                      administrative burden and potential financial losses they
                      can cause, make them a major bottleneck to achieving
                      revenue goals. When a chargeback comes in, the processing
                      cost can soar. In some cases it could double or even
                      triple - so be wary of any payment disputes that come your
                      way! With a little bit of caution and care it's possible
                      for hospitality leaders to safeguard their bottom line.
                    </p>
                    <p>
                      Register today and unlock the secrets to improving your
                      bottom line!
                    </p>
                    <p>
                      Understand how Hyperautomation can help you reduce
                      chargebacks, enhance revenue streams and make a real
                      difference for your business.
                    </p>
                    <p>
                      <span className="bold">Key Takeaways:</span>
                    </p>
                    <div>
                      <ul>
                        <li>
                          Learn how you can use Hyperautomation to reduce
                          revenue leakages due to chargebacks
                        </li>
                        <li>
                          Learn to identify quick-win use cases within
                          hospitality accounting processes.
                        </li>
                        <li>
                          Learn how to calculate ROI and maximize the benefits
                          of Intelligent Process Automation
                        </li>
                      </ul>
                    </div>
                    <br></br>
                    <div className="duration">
                      <p>
                        <span className="bold"> Event Details</span>
                      </p>
                      <p>
                        <span className="bold"> Date :</span>{' '}
                        <span>&emsp; Wednesday 22nd February, 2023</span>
                      </p>
                      <p>
                        <span className="bold">Time : </span>{' '}
                        <span> &emsp; 9 AM PST</span>
                      </p>
                      <p>
                        <span className="bold"> Duration : </span>
                        <span> &emsp; 1 hour</span>
                      </p>
                    </div>
                  </div>
                </Box>
                <Box {...col}>
                  <ContactMongo
                    buttontext="Book my slot"
                    formname="Webinar"
                    formtitle="Register Now!"
                    redirect={true}
                    name={url}
                  />
                </Box>
              </Box>
            </Container>
          </Section1Wrapper>
          <Section2Wrapper>
            <Container>
              <Heading {...whitetitle} content="Meet Your Presenter" />
              <Box {...sec2row}>
                <Box {...sec2col1}>
                  <div className="left_content margin_right boxContent">
                    <div className="image_holder">
                      <div className="overlay"></div>
                      <img
                        src={RaghuImage}
                        alt="raghu"
                        className="host_img"
                      ></img>
                    </div>
                    <p className="name">Raghuraman Ramamurthy </p>
                    <p className="linkedin">
                      <a
                        href="https://www.linkedin.com/in/raghuramanramamurthy/"
                        target="_blank"
                      >
                        @RaghuramanRamamurthy
                      </a>
                    </p>
                    <p className="description">
                      Parallel Entrepreneur and Business Leader with proven
                      results in delivering significant business value through
                      end-to-end digital transformation and automation. On a
                      mission to democratize Intelligent Process Automation by
                      making it easier to access and affordable and help
                      enterprises automate processes end to end.
                    </p>
                  </div>
                </Box>
                {/* <Box {...sec2col2}>
                  <div className="image_holder">
                    <div className="overlay"></div>
                    <img
                      src={AugustinImage}
                      alt="augustin"
                      className="host_img"
                    ></img>
                  </div>

                  <div className="right_content margin_left">
                    <p className="name">Augustin Gohil </p>
                    <p className="linkedin">
                      <a
                        href="https://www.linkedin.com/in/augustin-gohil/"
                        target="_blank"
                      >
                        @AugustinGohil
                      </a>
                    </p>
                    <p className="description">
                      Helping Enterprises Scale Operations Through Intelligent
                      Process Automation and digitally transformative solution.
                      Over 20 years of cross-industry experience and on a
                      mission to make intelligent process automation accessible
                      for enterprises of all sizes and empower their full-time
                      employees with intelligent digital workers.
                    </p>
                  </div>
                </Box> */}
              </Box>
            </Container>
          </Section2Wrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
Webinarpage.propTypes = {
  row: PropTypes.object,
};

Webinarpage.defaultProps = {
  margintop: {
    mt: '100px',
  },
  decoration: {
    backgroundColor: '#424996',
    py: '50px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'relative',
  },
  sec2row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  sec2col1: {
    flexBox: true,
    flexWrap: 'nowrap',
    width: ['100%', '100%', '100%', '48%', '48%'],
    mr: ['0', '0', '0', '2%', '2%'],
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-end',
      'flex-end',
    ],
  },
  sec2col2: {
    flexBox: true,
    flexWrap: 'nowrap',
    width: ['100%', '100%', '100%', '48%', '48%'],
    ml: ['0', '0', '0', '2%', '2%'],
    justifyContent: [
      'flex-end',
      'flex-end',
      'flex-end',
      'flex-start',
      'flex-start',
    ],
  },
  col: {
    width: ['100%', '100%', '100%', '48%', '48%'],
    mr: ['0', '0', '0', '1%', '1%'],
    ml: ['0', '0', '0', '1%', '1%'],
    pt: ['10px', '10px', '75px', '75px', '75px'],
    position: 'relative',
  },

  title: {
    as: 'h1',
    fontSize: ['32px', '36px', '38px', '38px', '40px'],
    fontWeight: '500',
    // color: '#0f2137',
    // color: "#fff",
    color: '#fff',
    letterSpacing: '0.025em',
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  subtitle: {
    as: 'h2',
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '500',
    color: '#d9d9d9',
    letterSpacing: '0.025em',
    mt: '20px',
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  sec2_title: {
    as: 'h3',
    fontSize: ['20px', '24px', '24px', '26px', '26px'],
    fontWeight: '700',
    color: '#202342',
    letterSpacing: '0.025em',
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'left',
  },
  whitetitle: {
    as: 'h3',
    fontSize: ['26px', '28px', '30px', '30px', '30px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '0.025em',
    mb: '40px',
    lineHeight: '1.31',
    mr: '28px',
    textAlign: 'center',
  },
};
export default Webinarpage;
